<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> GESTIONAR PROBADOR VIRTUAL </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <div class="col-12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'probadorVirtualPanel' }"
                    >Provador Viutal</router-link
                  >
                </p>
              </div>

              <v-col cols="12">
                <v-btn
                  small
                  title="Limpiar filtros"
                  fab
                  dark
                  color="blue-grey"
                  depressed
                  @click="limpiarFiltros()"
                >
                  <v-icon dark>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                <v-select
                  v-model="cmbCantidadRegistros"
                  item-text="texto"
                  item-value="valor"
                  :items="arrCantidadRegistros"
                  label="Cantidad de registros"
                  @change="listarRegistros()"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                <v-menu :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="formatFiltroFechaDesde"
                      dense
                      clearable
                      label="Desde"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="
                        paginaActual = 1;
                        filtroFechaDesde = '';
                        listarRegistros();
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filtroFechaDesde"
                    locale="es-es"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="12" cols="12" class="text-center">
                <v-btn class="my-0" color="info" elevation="" @click="filtrar()">
                  <v-icon>mdi-filter</v-icon> Filtrar
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-btn
                  :loading="btnReporteExcel"
                  fab
                  elevation=""
                  @click="reporteExcel()"
                >
                  <v-icon large color="success">mdi-file-excel</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-simple-table dense="" fixed-header="" ref="tblListado">
                  <template v-slot:default>
                    <thead class="text-no-wrap">
                      <tr>
                        <th>FECHA DE SUBIDA</th>
                        <th>IMÁGEN GRANDE</th>
                        <th>PESO DE IMÁGEN GRANDE</th>
                        <th>IMÁGEN MEDIANO</th>
                      </tr>
                    </thead>
                    <tbody class="text-no-wrap">
                      <tr
                        v-for="item in arrRegistrosTabla"
                        :key="item.idprobador_virtual"
                      >
                        <td
                          v-text="
                            $moment(item.fecha_subida).format('DD/MM/YYYY hh:mm:ss A')
                          "
                        ></td>
                        <td>
                          <a
                            :href="assetAWS('probador_virtual/' + item.imagen_original)"
                            target="_blank"
                            v-text="item.imagen_original"
                          ></a>
                        </td>
                        <td v-text="item.datos_imagen_original + ' KB'"></td>
                        <td>
                          <a
                            :href="assetAWS('probador_virtual/' + item.imagen_rostro)"
                            target="_blank"
                            v-text="item.imagen_rostro"
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p>
                  Mostrando del registro <span v-text="primerRegistro"></span> al
                  <span v-text="ultimoRegistro"></span> de un total de
                  <span v-text="totalRegistro"></span> filas
                </p>
              </v-col>

              <v-col cols="12 text-center" v-show="loaderListado">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>

              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-pagination
                  circle
                  v-model="paginaActual"
                  :length="cantidadprobadorVirtuales"
                  :total-visible="7"
                  @input="listarRegistros()"
                  :disabled="loaderListado"
                ></v-pagination>
              </v-col>

              <v-col cols="12" v-show="alertRegistroNoEcontrado">
                <v-alert type="error" dense outlined class="text-center">
                  NO HAY REGISTROS DISPONIBLES PARA MOSTRAR
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      arrCantidadRegistros: [
        {
          texto: 10,
          valor: 10,
        },
        {
          texto: 30,
          valor: 30,
        },
        {
          texto: 50,
          valor: 50,
        },
        {
          texto: 100,
          valor: 100,
        },
        {
          texto: 500,
          valor: 500,
        },
      ],
      loaderListado: false,
      alertRegistroNoEcontrado: false,
      cmbCantidadRegistros: 10,
      arrRegistrosTabla: [],
      paginaActual: 1,
      cantidadprobadorVirtuales: 1,
      primerRegistro: 0,
      ultimoRegistro: 0,
      totalRegistro: 0,
      iptBuscarRegisro: "",
      filtroFechaDesde: "",
      objModalEliminar: null,
      btnReporteExcel: false,
    };
  },

  computed: {
    formatFiltroFechaDesde() {
      return this.filtroFechaDesde
        ? this.$moment(this.filtroFechaDesde).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    listarRegistros() {
      this.arrRegistrosTabla = [];
      this.loaderListado = true;
      this.alertRegistroNoEcontrado = false;
      this.axios({
        method: "GET",
        url: "api/panel/probadorVirtual",
        params: {
          cantidadRegistros: this.cmbCantidadRegistros,
          paginaActual: this.paginaActual,
          filtroFechaDesde: this.filtroFechaDesde,
        },
      })
        .then((response) => {
          let listado = response.data.data;

          this.arrRegistrosTabla = listado.data;
          this.paginaActual = listado.current_page;
          this.cantidadprobadorVirtuales = listado.last_page;
          this.primerRegistro = listado.from;
          this.ultimoRegistro = listado.to;
          this.totalRegistro = listado.total;

          if (this.arrRegistrosTabla.length === 0) {
            this.alertRegistroNoEcontrado = true;
          } else {
            this.alertRegistroNoEcontrado = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .then(() => {
          this.loaderListado = false;
          this.$vuetify.goTo(this.$refs.tblListado);
        });
    },

    reporteExcel() {
      window.open(
        this.apiUrl(
          "api/panel/probadorVirtual/reporteExcel?filtroFechaDesde=" +
            this.filtroFechaDesde
        ),
        "_self"
      );

      /*this.btnReporteExcel = true;
      this.axios({
        method: "POST",
        url: "api/panel/probadorVirtual/reporteExcel",
        responseType: "blob",
        data: {
          filtroFechaDesde: this.filtroFechaDesde,
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let objUrl = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.setAttribute("href", objUrl);
            link.setAttribute(
              "download",
              "reporte_probadorVirtual_" + this.$moment().format("DD-MM-YYYY_hh-mm-ss")
            );
            link.click();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.btnReporteExcel = false;
        });*/
    },

    filtrar() {
      this.paginaActual = 1;
      this.listarRegistros();
    },

    limpiarFiltros() {
      this.cmbCantidadRegistros = 10;
      this.filtroFechaDesde = "";
      this.listarRegistros();
    },
  },

  created() {
    if (!this.validarPermiso("probadorVirtual.listado")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    this.listarRegistros();
  },
};
</script>
