var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"","max-width":""}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" GESTIONAR PROBADOR VIRTUAL ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('div',{staticClass:"col-12"},[_c('p',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"exact":"","to":{ name: 'inicioPanel' }}},[_vm._v("Inicio")]),_vm._v(" / "),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"exact":"","to":{ name: 'probadorVirtualPanel' }}},[_vm._v("Provador Viutal")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","title":"Limpiar filtros","fab":"","dark":"","color":"blue-grey","depressed":""},on:{"click":function($event){return _vm.limpiarFiltros()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{attrs:{"xl":"2","lg":"2","md":"2","sm":"12","cols":"12"}},[_c('v-select',{attrs:{"item-text":"texto","item-value":"valor","items":_vm.arrCantidadRegistros,"label":"Cantidad de registros","dense":"","outlined":""},on:{"change":function($event){return _vm.listarRegistros()}},model:{value:(_vm.cmbCantidadRegistros),callback:function ($$v) {_vm.cmbCantidadRegistros=$$v},expression:"cmbCantidadRegistros"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.formatFiltroFechaDesde,"dense":"","clearable":"","label":"Desde","readonly":""},on:{"click:clear":function($event){_vm.paginaActual = 1;
                      _vm.filtroFechaDesde = '';
                      _vm.listarRegistros();}}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"locale":"es-es"},model:{value:(_vm.filtroFechaDesde),callback:function ($$v) {_vm.filtroFechaDesde=$$v},expression:"filtroFechaDesde"}})],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"xl":"3","lg":"3","md":"3","sm":"12","cols":"12"}},[_c('v-btn',{staticClass:"my-0",attrs:{"color":"info","elevation":""},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.btnReporteExcel,"fab":"","elevation":""},on:{"click":function($event){return _vm.reporteExcel()}}},[_c('v-icon',{attrs:{"large":"","color":"success"}},[_vm._v("mdi-file-excel")])],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.arrRegistrosTabla.length > 0),expression:"arrRegistrosTabla.length > 0"}],attrs:{"cols":"12"}},[_c('v-simple-table',{ref:"tblListado",attrs:{"dense":"","fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"text-no-wrap"},[_c('tr',[_c('th',[_vm._v("FECHA DE SUBIDA")]),_c('th',[_vm._v("IMÁGEN GRANDE")]),_c('th',[_vm._v("PESO DE IMÁGEN GRANDE")]),_c('th',[_vm._v("IMÁGEN MEDIANO")])])]),_c('tbody',{staticClass:"text-no-wrap"},_vm._l((_vm.arrRegistrosTabla),function(item){return _c('tr',{key:item.idprobador_virtual},[_c('td',{domProps:{"textContent":_vm._s(
                          _vm.$moment(item.fecha_subida).format('DD/MM/YYYY hh:mm:ss A')
                        )}}),_c('td',[_c('a',{attrs:{"href":_vm.assetAWS('probador_virtual/' + item.imagen_original),"target":"_blank"},domProps:{"textContent":_vm._s(item.imagen_original)}})]),_c('td',{domProps:{"textContent":_vm._s(item.datos_imagen_original + ' KB')}}),_c('td',[_c('a',{attrs:{"href":_vm.assetAWS('probador_virtual/' + item.imagen_rostro),"target":"_blank"},domProps:{"textContent":_vm._s(item.imagen_rostro)}})])])}),0)]},proxy:true}])}),_c('p',[_vm._v(" Mostrando del registro "),_c('span',{domProps:{"textContent":_vm._s(_vm.primerRegistro)}}),_vm._v(" al "),_c('span',{domProps:{"textContent":_vm._s(_vm.ultimoRegistro)}}),_vm._v(" de un total de "),_c('span',{domProps:{"textContent":_vm._s(_vm.totalRegistro)}}),_vm._v(" filas ")])],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaderListado),expression:"loaderListado"}],attrs:{"cols":"12 text-center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":100,"width":7}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.arrRegistrosTabla.length > 0),expression:"arrRegistrosTabla.length > 0"}],attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"circle":"","length":_vm.cantidadprobadorVirtuales,"total-visible":7,"disabled":_vm.loaderListado},on:{"input":function($event){return _vm.listarRegistros()}},model:{value:(_vm.paginaActual),callback:function ($$v) {_vm.paginaActual=$$v},expression:"paginaActual"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.alertRegistroNoEcontrado),expression:"alertRegistroNoEcontrado"}],attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"text-center",attrs:{"type":"error","dense":"","outlined":""}},[_vm._v(" NO HAY REGISTROS DISPONIBLES PARA MOSTRAR ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }